import React, { useState, useEffect } from "react";
import { MinApp } from "../components/MinApp";
import { PageContext }from '../contexts/pageContext'
import Head from 'next/head';
import Script from 'next/script';
import { getConfig } from '../contexts/configs/index'

function FeedPage() {

  const shopDomain = process.env.NEXT_PUBLIC_API_URL;
  return (
    <>

    <Head>
      <title>Claros - Skincare</title>
      <link rel="icon" href="/logo.png" />
      <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      <meta name="description" content="Find the best skincare products perfect for your skin, curated by AI." />
      <meta property="og:title" content="Claros - Skincare" />
      <meta property="og:description" content="Find the best skincare products perfect for your skin, curated by AI." />
      <meta property="og:url" content="https://www.claros.so/skincare" />
      <meta property="og:type" content="website" />
      <meta property="twitter:title" content="Claros - Skincare" />
      <meta property="twitter:description" content="Find the best skincare products perfect for your skin, curated by AI." />
    </Head>
    <PageContext.Provider value={getConfig("skincare")}>
        {/* 50% of the time, we want to show shitty app instead of app*/}
        <>
       {<MinApp/> }

        </>
    </PageContext.Provider>

    </>
  )
}
export default FeedPage
